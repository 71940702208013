import { Col, Container, Row } from "react-bootstrap";

import { mdiArrowRightBoldCircle, mdiHeart } from '@mdi/js';
import Icon from '@mdi/react';

import { Spacer, SpacerBig } from "./Dividers";
import blorb2 from "../assets/img/BlorbSprite_2.png";

export const EarlyAccess = () => {
    return (
        <section id="earlyaccess" className="earlyaccess section neb-blue">
            <Container>
                <SpacerBig />
                <h1>Early Access</h1>
                <Row>
                    <Col sm={12} md={6}>
                        <Spacer />
                        <h2>You can help!</h2>
                        <p>
                            Elysian Eclipse is completely funded by its community and
                            independent of any big investors or shareholders.
                            Our goal isn't to make money, but a great game!
                            <br />
                            <br />
                            All of our aquatic tier or higher supporters receive regular early access versions
                            of the game to test the latest features.
                            You too can help us fund the development of EE
                            and get early access or just a place in the credits.
                            <br />
                            <br />
                            Thank you! Without you this wouldn't be possible!
                            <span>
                                <Icon path={mdiHeart} size={1.5} color="plum" style={{ marginLeft: 10 }} />
                            </span>
                        </p>
                        <a
                            href="https://ko-fi.com/wauzmons/tiers"
                            target="_blank"
                            rel="noreferrer">
                            <button className="action-button action-button-min">
                                Support on Ko-fi
                                <Icon path={mdiArrowRightBoldCircle} size={1} color="white" />
                            </button>
                        </a>
                        <a
                            href="https://www.patreon.com/wauzmons"
                            target="_blank"
                            rel="noreferrer">
                            <button className="action-button action-button-min">
                                or Patreon (Deprecated)
                                <Icon path={mdiArrowRightBoldCircle} size={1} color="white" />
                            </button>
                        </a>
                        <Spacer />
                        <h2>Free Demo Version</h2>
                        <p>
                            To celebrate the first birthday of the project,
                            there now is a free version of EE,
                            which only features the cell and aquatic stages,
                            aswell as multiple editors.
                            <br />
                            <br />
                            If you are having fun with the demo,
                            please consider supporting the project on Ko-fi
                            for the full early access version!
                        </p>
                        <a
                            href="https://cdn.elysian-eclipse.com/free/EE_Demo_Free_0.5.0.zip"
                            target="_blank"
                            rel="noreferrer">
                            <button className="action-button action-button-min">
                                Download the Free Demo
                                <Icon path={mdiArrowRightBoldCircle} size={1} color="white" />
                            </button>
                        </a>
                        <Spacer />
                        <h2>System Requirements</h2>
                        <p>
                            <b>OS: </b>Windows 10 / 11<br />
                            <b>Processor: </b>INTEL CORE I5-8400 or AMD RYZEN 3 3300X<br />
                            <b>Memory: </b>8 GB RAM<br />
                            <b>Graphics: </b>NVIDIA GEFORCE GTX 1060 3 GB or AMD RADEON RX 580 4 GB<br />
                            <b>DirectX: </b>Version 11<br />
                            <b>Storage: </b>5 GB available space<br />
                            <b>Notes: </b>Additional disk space required to store player-made creations.
                        </p>
                    </Col>
                    <Col sm={12} md={6}>
                        <img src={blorb2} alt="Blorb" />
                    </Col>
                </Row>
                <Spacer />
            </Container>
        </section>
    );
}